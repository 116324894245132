@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Raleway:wght@400;700&display=swap');

// for tablet and below
$lg: '992px';

// for mobile and below
$md: '769px';

//font-family: 'Bebas Neue', cursive;
//font-family: 'Raleway', sans-serif;

body {
  margin: 0;
  font-family: 'Raleway', sans-serif, 'Helvetica Neue', sans-serif, 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, button {
  font-family: 'Bebas Neue', cursive;
}

button {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

.d-flex {
  display: flex;
}

.align-center {

}

.infinite_rotate{
  animation: rotate 1.5s linear infinite;
}
@keyframes rotate{
  to{ transform: rotate(360deg); }
}

.unityCanvas {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.canvas_size {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: absolute;
}

#event_home {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  background: url('./Modules/Event/home_cicg.jpeg') no-repeat center center;
  background-size: cover;
  h1 {
    font-size: 72px;
    margin: 0;
    margin-top: 16px;
    line-height: 72px;
  }
  .top_white_bg {
    height: 100vh;
    width: 100vw;
    left: -40vw;
    background: white;
    transform: skewX(-45deg);
    position: relative;
    svg.logo {
      position: absolute;
      top: 10px;
      left: 25vw;
      transform: skewX(45deg);
    }
  }
  .event {
    position: absolute;
    top: 240px;
    left: 45vw;
    transform: skewX(45deg);
  }
  .bot_white_bg {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 175px 175px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    svg {
      position: absolute;
      left: -75px;
      bottom: -150px;
    }
  }
}

.front_of_canvas {
  z-index: 11;
}

#burger_menu {
  position: absolute;
  top: 32px;
  right: 30px;
  background: rgba(255, 255, 255, 0.08);
  width: 72px;
  height: 72px;
  border-radius: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  span {
    display: block;
    width: 24px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    background: white;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    opacity 0.55s ease;
    &:first-child {
      transform-origin: 0% 0%;
    }
    &:nth-last-child(2) {
      transform-origin: 0% 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

#menu {
  color: #333333;
  ul {
    margin-top: 60px;
    li  {
      margin-bottom: 32px;
      a, button, span {
        color: #333333;
        font-size: 40px;
        text-align: left;
      }
      span {
        color: #D8003F;
        font-family: 'Bebas Neue', cursive;
        cursor: pointer;
      }
    }
  }
}

.back_menu {
  display: flex;
  align-items: center;
  margin-top: 40px;
  h2 {
    margin: 0;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.16);
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 40px;
    svg {
      path {
        fill: white;
      }
    }
  }
}

#overflow {
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.7);
  width: 65vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    width: 160px;
    height: 160px;
    background: rgba(255, 255, 255, 0.2);
    border: solid 1px white;
    transform: rotate(45deg);
    span {
      display: block;
      transform: rotate(-45deg);
    }
  }
}

#main_logo {
  position: absolute;
  top: 30px;
  left: 30px;
}

#right_panel {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 35vw;
  height: 100vh;
  padding: 40px;
  color: white;
  box-sizing: border-box;
  overflow-y: scroll;
  &.bg_white {
    color: #131415 !important;
  }
  .top_right_panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    .left {
      max-width: 85%;
    }
  }
  .close_menu {
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgba(0, 0, 0, 0.16);
    border-radius: 72px;
    top: 0px;
    right: 0px;
    position: absolute;
    font-size: 20px;
    line-height: 20px;
    svg {
      path {
        fill: white;
      }
    }
  }
  h1 {
    font-size: 24px;
  }
  .bottom_logo {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  iframe {
    width: 100% !important;
    min-height: 240px;
  }
}

#bottom {
  position: absolute;
  bottom: 6px;
  width: 96%;
  padding-left: 2%;
  .title {
    color: white;
    h1 {
      margin: 0;
      margin-left: 14px;
    }
    button {
      color: white;
      font-size: 25px;
      &::after {
        content: '-';
        margin-left: 14px;
      }
    }
  }
  .bot_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px #cccccc;
    padding-top: 12px;
    margin-top: 16px;
    ul {
      display: flex;
      flex-direction: row;
      li {
        &:after {
          padding-left: 10px;
          padding-right: 10px;
          content: '-';
          color: white;
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
        * {
          color: white;
          font-size: 13px;
        }
      }
    }
  }
}

.long_listing {
  ul {
    overflow-y: scroll;
    li.main_list {
      border-bottom: solid 1px rgba(255, 255, 255, 0.35);
      button.main_button {
        color: white;
        text-align: left;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-family: 'Raleway', sans-serif;
          font-weight: 700;
        }
        .date {
          font-weight: normal;
          font-size: 0.75em;
          color: rgba(255, 255, 255, 0.85);
        }
        &::after {
          transition: all 0.3s;
          content:url('./Arrow_Down.svg');
        }
      }
      .main_list_content {
        padding-bottom: 20px;
        img {
          max-width: 200px;
        }
      }
      &.visible {
        button {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
      &.invisible {
        transition: all 0.3s;
        .main_list_content {
          display: none;
        }
      }
      .white_button {
        color: white;
      }
    }
  }
  .other_program {
    opacity: 0.5;
    margin-top: 80px;
  }
}

.react-reveal {
  position:relative;
  z-index: 15;
}

.button {
  text-transform: uppercase;
  padding: 12px 46px;
  border-radius: 22px;
  font-size: 20px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s;
  border: solid 1px transparent;
  &_circle {
    border-radius: 100px;
  }
  &_white {
    color: #D8003F;
    background: white;
  }
  &_red {
    color: white;
    background: #D8003F;
    &:hover {
      color: #D8003F;
      border: solid 1px #D8003F;
      background: white;
    }
  }
  &_light {
    font-size: 15px;
  }
  &_lower {
    text-transform: capitalize;
    &:hover {
      .lds-ellipsis {
        div {
          background: #D8003F;
        }
      }
    }
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: auto;
  top: -5px;
  div {
    position: absolute;
    top: 0px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 4px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 4px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 16px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 28px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mb-2 {
  margin-bottom: 20px;
}

.wysiwyg {
  a {
    display: inline-block;
    color: white;
    text-decoration: none;
  }
  ul li, ol li {
    list-style: circle;
    margin-left: 14px;
  }
}

.Papercups-toggleButton {
  width: 65px !important;
  height: 65px !important;
}

.Papercups-toggleButtonContainer {
  bottom: 85px !important;
  right: 30px !important;
}

.Papercups-chatWindowContainer {
  right: 110px !important;
}

#event_about {
  height: 100%;
  img {
    max-width: 250px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.hide_desktop {
  display: none;
}

/**
  Media query for tablet and below
 */
@media (max-width : $md) {
  #burger_menu, #main_logo {
    top: 20px;
    right: 20px;
  }

  #overflow {
    width: 0vw;
  }

  #right_panel {
    width: 100vw;
    left: 0;
  }

  #event_home {
    .event {
      transform: none;
      left: 5vw;
    }
    .top_white_bg {
      transform: none;
      left: 0;
      width: 90vw;
      svg.logo {
        transform: none;
        left: 5vw;
      }
    }
  }

  #bottom {
    width: 100%;
    svg {
      display: none;
    }
    ul {
      display: none !important;
    }
    .bot_bottom {
      display: none;
    }
  }

  .Papercups-toggleButton {
    width: 60px !important;
    height: 60px !important;
  }

  .Papercups-toggleButtonContainer {
    bottom: 20px !important;
    right: 10px !important;
  }

  .hide_desktop {
    display: block;
  }

  .small_menu {
    li {
      margin-bottom: 16px !important;
    }
    * {
      font-size: 13px !important;
    }
  }
}
#login{
  input{
    padding:10px;
    margin-right:10px;
    border:1px solid #ccc;
    width:200px;
    outline: none !important;
  }

}
.login_error{
  font-weight: bold;
  padding:5px 0;
}
